import {
    TOGGLE_ITEM_SELECTION,
    CLEAR_SELECTIONS,
    SET_ALL_SELECTED,
    INITIALIZE_SELECTIONS,
    SET_GLOBAL_CAP_LEVEL,
    SET_EXP_DATE,
    SET_LOCAL_FILTERS,
    RESET_ALL_DASHBOARDS,
    SET_TOTAL_COST
} from '../actions/selectionActions';

import moment from 'moment';
import { getNextThirdFriday } from '../Utilities/ThirdFriday';

const initialState = {
    CallWriting: {
        selectedItems: {},
        allSelected: true,
        GlobalCapLevel: 110,
        ExpDate: moment(getNextThirdFriday(moment.utc().add(1, 'month'))).format('YYYY-MM-DD'),
        localFilters: {},
        totalCost: 0
    },
    PutBuying: {
        selectedItems: {},
        allSelected: false,
        GlobalCapLevel: 90,
        ExpDate: moment(getNextThirdFriday(moment.utc().add(1, 'month'))).format('YYYY-MM-DD'),
        localFilters: {},
        totalCost: Number(sessionStorage.getItem('totalCost')) || 0 // Initialize from sessionStorage
    }
};

function selectionReducer(state = initialState, action) {
    const { tabType } = action.payload ?? {};
    if (!tabType) return state;

    switch (action.type) {
        case RESET_ALL_DASHBOARDS:
            console.log(" inside reset")
            sessionStorage.removeItem('totalCost');  // Reset session storage
            return { ...initialState };

        case TOGGLE_ITEM_SELECTION: {
            const { ticker, isSelected } = action.payload;
            return {
                ...state,
                [tabType]: {
                    ...state[tabType],
                    selectedItems: {
                        ...state[tabType].selectedItems,
                        [ticker]: isSelected
                    },
                    allSelected: Object.values(state[tabType].selectedItems).every(t => t)
                }
            };
        }

        case SET_ALL_SELECTED: {
            const { isSelected: allSelected, tickers } = action.payload;

            // Ensure all tickers are set based on the allSelected flag
            const updatedSelectedItems = tickers.reduce((acc, ticker) => {
                acc[ticker] = allSelected;
                return acc;
            }, {});

            return {
                ...state,
                [tabType]: {
                    ...state[tabType],
                    selectedItems: updatedSelectedItems,
                    allSelected
                }
            };
        }

        case CLEAR_SELECTIONS:
            return {
                ...state,
                [tabType]: {
                    ...state[tabType],
                    selectedItems: {},
                    allSelected: false
                }
            };

            case INITIALIZE_SELECTIONS:
                console.log(" inside init")
                const isCallWriting = tabType === 'CallWriting';
                const initialSelectionState = action.payload?.initialData || {}; // Get new tickers from action payload.
            
                // Reset selectedItems and allSelected for the new portfolio
                const newSelectedItems = Object.keys(initialSelectionState).reduce((acc, ticker) => {
                    acc[ticker] = isCallWriting; // Select all for CallWriting, deselect for PutBuying.
                    return acc;
                }, {});
            
                return {
                    ...state,
                    [tabType]: {
                        ...state[tabType],
                        selectedItems: newSelectedItems,
                        allSelected: isCallWriting, // Select all for CallWriting, deselect for PutBuying.
                    }
                };
            

        case SET_GLOBAL_CAP_LEVEL:
            return {
                ...state,
                [tabType]: {
                    ...state[tabType],
                    GlobalCapLevel: action.payload.level
                }
            };

        case SET_EXP_DATE:
            return {
                ...state,
                [tabType]: {
                    ...state[tabType],
                    ExpDate: action.payload.date
                }
            };

        case SET_LOCAL_FILTERS:
            return {
                ...state,
                [tabType]: {
                    ...state[tabType],
                    localFilters: {
                        ...state[tabType].localFilters,
                        ...action.payload.filters
                    }
                }
            };

        // New action for setting total cost
        case SET_TOTAL_COST: {
            const { totalCost } = action.payload;
            sessionStorage.setItem('totalCost', totalCost);  // Update sessionStorage
            return {
                ...state,
                [tabType]: {
                    ...state[tabType],
                    totalCost
                }
            };
        }

        default:
            return state;
    }
}

export default selectionReducer;
